.grid {
  display: grid;
  margin-top: 100px;
  grid-template-columns: repeat(2, 1fr); /* 2 kolumner */
  gap: 10px;
}

.card {
  perspective: 1000px;
  cursor: pointer;
}

.inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  transform: rotateY(0deg); /* Initial state showing the back */
}

.card.flipped .inner {
  transform: rotateY(180deg);
}

.front, .back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  overflow: hidden;
  border-radius: 10px;
  border: #000 1px solid;
  background-color: #ffffff;
  padding: 0px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.front {
  background-color: white;
  color: black;
  transform: rotateY(180deg); /* Make the front face hidden initially */
}

.back {
  background-color: gray;
  background-image: url('./images/memory-back.png'); /* Path to your image */
  background-size: cover;
  transform: rotateY(0deg);

}

