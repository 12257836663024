

.App {
  z-index: 2;
  font-family: "ProtestRiot-Regular";
  background-image: url("images/background-tile.png");
}

a {
  color: #000000;
  text-decoration: none;
}
@font-face {
  font-family: "ProtestRiot-Regular";
  src: local("ProtestRiot-Regular"),
    url(./fonts/ProtestRiot-Regular.ttf) format("truetype");
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

.home-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  align-items: center;
}

.image-detail-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  align-items: center;
}

.centered-container {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column; /* Align children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically, if needed */
  height: 100%; /* Optional: You might want to set a height */
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;  
  color: black;
}

input {
  height: 60px;
  border: 1px solid #000000;
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.image-actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  
}

.image-box {
  border-radius: 10px;
  border: #000 1px solid;
  height: auto;
  background-color: #ffffff;
  padding: 0px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.image-title {
  font-size: 24px;
}
.category-detail {
  padding-top: 20px;
}
.category-detail Link {
  text-decoration: none;
  color: #ff0000;

}
.image-container {
  position: relative;
  max-width: 95%; /* Set the maximum width of the container */
  padding-top: 80px;
  margin: 0 auto; /* Center the container */
  text-align: center; /* Center the image horizontally */
  justify-content: center; /* Centers children horizontally */
}

.image-overlay {
  position: absolute; /* Position absolutely within the container */
  bottom: 1px; /* Place at the top of the container */
  left: 1px; /* Place at the left of the container */
  right: 1px; /* Place at the right of the container */
  width: 100%; /* Occupy the entire width of the container */
  background: rgba(255, 255, 255, 0.8); /* Add a semi-transparent background */
  display: flex;
  margin-bottom: 6px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;  
  justify-content: space-between; /* Space out the buttons and title horizontally */
  align-items: center; /* Center items vertically */  
}
.fill {
  width: 100%; /* Set the width to 100% of the container */
  height: 100%; /* Set the height to 100% of the container */
}
.loading-indicator-container {
  width: 100%; /* Set the width to 100% of the container */    
  border-radius: 20px;
  border: #000 1px solid;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  display: flex; /* Establish this element as a flex container */
  flex-direction: row; /* Arrange children from top to bottom */
  align-items: center; /* Center children horizontally */
  justify-content: space-around; /* Center children vertically */
}

.scaled-image {
  max-width: 90%; /* Set the maximum width to 100% of the container */
  height: auto; /* Maintain the aspect ratio */
  border-radius: 20px;
  border: #000 1px solid;
  height: auto;
  background-color: #ffffff;
  padding: 0px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

h2 {
  font-size: 42px;
  padding-left: 20px;
  padding-right: 20px;
}

h3 {
  font-size: 24px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.image-grid {
  display: grid;
  gap: 16px;
  padding: 20px;
  z-index: 2;
}

.category-detail {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.bottom-link-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding: 16px;
  gap: 16px;
}

.bottom-link {
  text-align: center;
}


.image-grid a {
  text-decoration: none;
  color: #000000;
}
.category-header {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.page-title {
  z-index: 2;
  text-align: center;
  top: -2px;
  
}

/* For mobile phones: */
@media (max-width: 800px) {
  .grid-box {
    width: 180px;
    height: 180px;
  }
  .back-button {
    left: 8px;
  }
  .print-button {
    right: 8px;
  }
  .page-title {
    font-size: 36px;
    top: 10px;
  }
  .image-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on small screens */
  }
  input {
    width: 390px;
    font-size: 24px;
  }
  .high-res-img {
    height: 260px;
    margin-top: 40px;
  }
}

/* For tablets and laptops: */
@media (min-width: 801px) {
  .grid-box {
    width: 200px;
    height: 200px;
  }
  .back-button {
    left: 100px;
  }
  .print-button {
    right: 100px;
  }
  .page-title {
    font-size: 56px;
  }
  .image-grid {
    grid-template-columns: repeat(
      3,
      1fr
    ); 
  }
  input {
    width: 600px;
    font-size: 24px;
  }
  .high-res-img {
    height: 260px;
    margin-top: 40px;
  }
}

/* For tablets and laptops: */
@media (min-width: 1001px) {
  .grid-box {
    width: 200px;
    height: 200px;
  }
  .back-button {
    left: 100px;
  }
  .page-title {
    font-size: 56px;
  }
  .image-grid {
    grid-template-columns: repeat(
      4,
      1fr
    ); /* 4 columns on medium screens and above */
  }
  input {
    width: 600px;
    font-size: 24px;
  }
  .high-res-img {
    height: 300px;
    margin-top: 40px;
  }
}

@media (min-width: 1300px) {
  .grid-box {
    width: 200px;
    height: 200px;
  }
  .back-button {
    left: 100px;
  }
  .print-button {
    right: 100px;
  }
  .page-title {
    font-size: 56px;
  }
  .image-grid {
    grid-template-columns: repeat(6, 1fr); /* 6 columns on large screens */
  }
  .input {
    width: 800px;
    font-size: 24px;
  }
  .high-res-img {
    height: 300px;
  }
}
.image-grid p {
  margin: 0;
  padding: 8px;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
}
.image-grid img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  border: #000 0px solid;
  height: auto;
}

.add-new {
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;  
  font-size: 36px;
  text-align: center;
}

.image-grid .grid-box {
  overflow: hidden;
  border-radius: 10px;
  border: #000 1px solid;
  background-color: #ffffff;
  padding: 0px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.image-grid .image-description {
  width: 100%;
  padding: 10px;
}

.category-description {
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  text-align: center;
}

.image-grid .grid-box-progress-container {
  width: 100%;
  height: 205px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.high-res-img {
  z-index: 2;
}
.small-logo {
  width: 120px;
}
.fill {
  height: 100%;
  width: auto;
}

.top-left {
  position: fixed;
  top: 0;
  left: 0;
  width: 100px;
  z-index: 3;
}

.top-right {
  position: fixed;
  top: 0;
  right: 0;
  width: 100px;
  z-index: 3;
}

.bottom-left {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100px;
  z-index: 3;
}

.bottom-right {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 120px;
  z-index: 3;
}

.only-print {
  display: none;
}

.print-logo {
  width: 15%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

@media print {
  .only-print {
    display: block; /* Or 'inline', depending on your layout */
  }
  .scaled-image {
    max-width: 100%; /* Set the maximum width to 100% of the container */
    height: auto; /* Maintain the aspect ratio */
    border: none;
    height: auto;
    background-color: #ffffff;
    padding: 0px;
    box-shadow: none;
  }
  .no-print {
    display: none !important;
  }
}

.back-button {
  position: fixed;
  background-color: #333; /* Dark background for contrast */
  color: white; /* White text */
  padding: 5px 5px; /* Bigger button size */
  border: none;
  cursor: pointer;
  align-items: center;
  border-radius: 50px; /* Slightly rounded corners for a modern look */
  z-index: 5;
  top: 10px;
  font-size: 48px;  
  display: flex; /* Make it a flex container */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
}

.print-button-label {
  margin-left: 16px;
  margin-top: 3px;
  margin-right: 8px;
  font-size: 36px;
}

.print-button {
  position: fixed;
  background-color: #333; /* Dark background for contrast */
  color: white; /* White text */
  padding: 5px 5px; /* Bigger button size */
  border: none;
  border-radius: 50px; /* Slightly rounded corners for a modern look */
  z-index: 5;  
  font-size: 48px;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  
  display: flex; /* Make it a flex container */
  justify-content: center; /* Center content horizontally */
}

.button {
  position: block;
  background-color: #333; /* Dark background for contrast */
  color: white; /* White text */
  padding: 5px 25px; /* Bigger button size */
  border: none;
  border-radius: 50px; /* Slightly rounded corners for a modern look */
  z-index: 2;  
  font-size: 40px;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  
  display: flex; /* Make it a flex container */
  justify-content: center; /* Center content horizontally */
}

.search-container {
  display: flex;
  flex-direction: column; /* Aligns children vertically */
  align-items: center;    /* Centers children horizontally */
  justify-content: center; /* Optional: Centers children vertically if you also want that */
}

/* Ensures the inner div holding SearchBox stretches to fit the container if needed */
.search-container > div {
  width: 100%;            /* Takes full width to center content inside it */
  display: flex;
  justify-content: center;
}

.ais-SearchBox .ais-SearchBox-input {
  height: 60px;
  border: 1px solid #000000;
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.ais-SearchBox .ais-SearchBox-submit,
.ais-SearchBox .ais-SearchBox-reset {
  display: none; /* Hide the search and reset icons */
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
  background-color: #F7CC5C;
  border-top: 1px solid #000;
  box-shadow: 0px 0px 5px rgba(218, 198, 198, 0.2);
  text-align: center;
  padding: 1rem;
  
}

.footer a {
  text-decoration: none;
  color: #000;
}

.footer a:hover {
  text-decoration: underline;
}


.category-chips-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.category-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  margin-left: 50px;
  margin-top: 16px;
}

.chip {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 36px;
  background-color: #000000;
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.chip:hover {
  background-color: #666;
}

.AdSlot{
  margin: 0 auto;
  margin-top: 16px;
  height: 250px;
  width: 970px;
  display: block;
}

@media (min-width: 1280px) {
  .AdSlot{
    width: 728px;
    height: 90px;
  }
}

@media (max-width: 1000px) {
  .AdSlot{
      width: 728px;
      height: 90px;
  }
}

@media (max-width: 750px) {
  .AdSlot{
      width: 336px;
      height: 280px;
  }
}